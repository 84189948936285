export const versionInfo = ((): VersionInfo => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const gitVersion = require('./git-version.json');
    return gitVersion;
  } catch (error) {
    console.log('git-version.json file is not found');
    // In dev the file might not exist:
    return { tag: 'v0.0.0', hash: 'local' };
  }
})();

export interface VersionInfo {
  dirty?: boolean;
  raw?: string;
  hash: string;
  distance?: number;
  tag: string;
  semver?: {
    options?: {
      loose?: boolean;
      includePrerelease?: boolean;
    };
    loose?: boolean;
    raw?: string;
    major?: number;
    minor?: number;
    patch?: number;
    prerelease?: [];
    build?: [];
    version?: string;
  };
  suffix?: string;
  semverString?: string;
}
