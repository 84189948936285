import { Routes } from '@angular/router';

export const eventRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/event/feat-events').then(m => m.EventsComponent),
  },
  {
    path: ':eventId',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/event/feat-event-details').then(m => m.EventDetailsComponent),
  },
];
