<div class="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
  @for (solution of businessSolutions; track $index) {
    <div class="content-container bg-white flex flex-col place-items-stretch">
      <div class="block relative w-full mb-3 min-h-48">
        <img [ngSrc]="solution.image" fill class="object-cover" alt="" />
      </div>

      <div class="flex flex-col px-4 pb-4 gap-3 text-center flex-grow">
        <span class="font-bold text-xl">{{ solution.header }}</span>

        <span class="flex-grow">{{ solution.text }}</span>

        <a [href]="solution.link" target="_blank">
          <p-button styleClass="w-full" [label]="viewWebsiteLabel"></p-button>
        </a>
      </div>
    </div>
  }
</div>
