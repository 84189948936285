{
  "dirty": true,
  "raw": "v1.68.0-16-g9eba7f38e-dirty",
  "hash": "g9eba7f38e",
  "distance": 16,
  "tag": "v1.68.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.68.0",
    "major": 1,
    "minor": 68,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.68.0"
  },
  "suffix": "16-g9eba7f38e-dirty",
  "semverString": "1.68.0+16.g9eba7f38e.dirty"
}