import { Routes } from '@angular/router';

export const requestRoutes: Routes = [
  {
    path: '',
    redirectTo: 'send',
    pathMatch: 'full',
  },
  {
    path: 'send',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/request/feature-send-request').then(
        m => m.SendRequestComponent,
      ),
  },
];
