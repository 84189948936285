import { Injectable } from '@angular/core';

type Theme =
  | 'theme-dark-blue'
  | 'theme-light-blue'
  | 'theme-dark-green'
  | 'theme-light-green'
  | 'theme-orange'
  | 'theme-red';

const themeStorageKey = 'theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {
    const currentTheme = sessionStorage.getItem(themeStorageKey);
    if (currentTheme) {
      this.addThemeToBody(currentTheme as Theme);
    } else {
      const searchParams = window.location.href
        ? new URL(window.location.href)?.searchParams
        : undefined;

      const referredTheme = searchParams?.get('theme');
      let theme: Theme;

      switch (referredTheme) {
        case 'darkblue':
          theme = 'theme-dark-blue';
          break;

        case 'lightblue':
          theme = 'theme-light-blue';
          break;

        case 'darkgreen':
          theme = 'theme-dark-green';
          break;

        case 'lightgreen':
          theme = 'theme-light-green';
          break;

        case 'orange':
          theme = 'theme-orange';
          break;

        case 'red':
          theme = 'theme-red';
          break;

        default:
          theme = 'theme-dark-blue';
          break;
      }

      sessionStorage.setItem(themeStorageKey, theme);

      this.addThemeToBody(theme);
    }
  }

  private addThemeToBody(theme: Theme) {
    document.body.classList.add(theme);
  }
}
