import { Routes } from '@angular/router';

export const exploreRoutes: Routes = [
  {
    path: '',
    redirectTo: 'thickener-explorer',
    pathMatch: 'full',
  },
  {
    path: 'thickener-explorer',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/explore/feat-thickener-explorer').then(
        m => m.ThickenerExplorerComponent,
      ),
  },
];
