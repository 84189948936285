import { Routes } from '@angular/router';

export const productRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/product/feature-product-search').then(
        m => m.ProductSearchComponent,
      ),
  },
  {
    path: 'pcf',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/product/feature-product-details').then(m => m.PCFComponent),
  },
  // We maintain two routes for product details, one with slug and one without
  {
    path: ':id',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/product/feature-product-details').then(
        m => m.ProductDetailsComponent,
      ),
  },
  {
    path: ':slug/p/:id',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/product/feature-product-details').then(
        m => m.ProductDetailsComponent,
      ),
  },
];
