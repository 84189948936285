import { Environment } from '@ev-portals/cp/frontend/shared/util';

import { mpcBaseUrlDev } from './base-urls';
import { localEnvironment } from './environment.local';

export const environment: Environment = {
  ...localEnvironment,
  production: false,
  environment: 'dev',
  apiMockData: false,
  authClientId: '21aa160a-4891-4046-afd2-072f2a6590ad',
  baseUrl: mpcBaseUrlDev,
  apiBaseUrl: mpcBaseUrlDev,
  appInsights: {
    instrumentationKey: 'dc465359-7d1d-4dcc-833a-590a995f5d60',
  },
  footerLinks: {
    automotiveTransportation: '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a61d61347',
    miningSolutions: '',
    nutrition: '',
    oilfieldChemicals:
      '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a6405139b/8a8081ad85ed417f0185ed96375444db',
    plasticAdditives:
      '/8a8082b285ea8eca0185ed1e25617a63/8a8082b285ea8eca0185ed2a606512f5/8a8081ad85ed417f0185ed9631c044c5/8a8082f885ed417b0185edbf0b601cc6',
  },
};
