import { Routes } from '@angular/router';

export const cartRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/purchase/feature-purchase').then(m => m.PurchaseComponent),
  },
  {
    path: 'confirmation',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/purchase/feature-purchase').then(
        m => m.OrderConfirmationPageComponent,
      ),
  },
];
