import { Type } from '@angular/core';
import { Routes } from '@angular/router';
import { profileRoutes } from '@ev-portals/cp/frontend/dashboard/shell';
import { eventRoutes } from '@ev-portals/cp/frontend/event/shell';
import { exploreRoutes } from '@ev-portals/cp/frontend/explore/shell';
import {
  LandingPageComponent,
  LandingPageHeaderComponent,
} from '@ev-portals/cp/frontend/info/feature-landing-page';
import { infoRoutes } from '@ev-portals/cp/frontend/info/shell';
import { productRoutes } from '@ev-portals/cp/frontend/product/shell';
import { cartRoutes } from '@ev-portals/cp/frontend/purchase/shell';
import { requestRoutes } from '@ev-portals/cp/frontend/request/shell';
import {
  autoLoginGuard,
  CheckoutPermissionGuard,
  LocationGuard,
  UserGuard,
} from '@ev-portals/cp/frontend/shared/auth/util';
import { BaseLayoutComponent, PageNotFoundComponent } from '@ev-portals/cp/frontend/shared/feature';
import { LocationSelectionComponent } from '@ev-portals/cp/frontend/user/feature-user-profile';

export const appRoutes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
        data: {
          headerComponent: LandingPageHeaderComponent,
        },
        canActivate: [autoLoginGuard()],
      },
      {
        path: 'login',
        loadComponent: loadLoginComponent,
      },
      {
        path: 'events',
        children: eventRoutes,
        canActivate: [UserGuard],
      },
      {
        path: 'info',
        children: infoRoutes,
      },
      {
        path: 'cart',
        children: cartRoutes,
        canActivateChild: [UserGuard, LocationGuard, CheckoutPermissionGuard],
      },
      {
        path: 'products',
        children: productRoutes,
        canActivateChild: [LocationGuard],
      },
      {
        path: 'requests',
        children: requestRoutes,
        canActivateChild: [UserGuard, LocationGuard],
      },
      {
        path: 'select-location',
        component: LocationSelectionComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'user',
        children: profileRoutes,
        canActivateChild: [UserGuard, LocationGuard],
      },
      {
        path: 'explore',
        children: exploreRoutes,
        canActivateChild: [UserGuard],
      },
      {
        path: 'error',
        loadChildren: () => import('./error.routes').then(m => m.errorRoutes),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

function loadLoginComponent(): Promise<Type<unknown>> {
  return import('@ev-portals/cp/frontend/shared/auth/feature-login').then(m => m.LoginComponent);
}
