import { Routes } from '@angular/router';

export const infoRoutes: Routes = [
  {
    path: 'contact',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/info/feature-contact').then(m => m.ContactComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/info/feature-privacy-policy').then(
        m => m.PrivacyPolicyComponent,
      ),
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/info/feature-terms-and-conditions').then(
        m => m.TermsAndConditionsComponent,
      ),
  },
  {
    path: 'terms-of-use',
    loadComponent: () =>
      import('@ev-portals/cp/frontend/info/feature-terms-of-use').then(m => m.TermsOfUseComponent),
  },
];
