import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Button } from 'primeng/button';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage, Button],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  readonly businessSolutions: {
    header: string;
    text: string;
    image: string;
    link: string;
  }[] = [
    {
      header: $localize`Mining Solutions`,
      image: 'assets/landingPage/Mining.webp',
      text: $localize`BASF’s Mining Solutions business offers a diverse range of mineral processing and hydrometallurgical chemistries and technologies to improve process efficiencies and aid the economic extraction of valuable resources`,
      link: 'https://energy-resources.basf.com/global/en/mining_solutions.html',
    },
    {
      header: $localize`Plastic Additives`,
      image: 'assets/landingPage/PlasticAdditives.webp',
      text: $localize`At BASF Plastic Additives, we are focused on delivering solutions that contribute to the sustainability of plastics: making them lighter, stronger, and more durable. We innovate with partners in the plastics recycling value chain to create a circular economy. Join us on the journey: create new VALue for plastics in the ERA of Sustainability with VALERAS™`,
      link: 'https://plastics-rubber.basf.com/global/en/plastic_additives.html',
    },
    {
      header: $localize`Fuel and Lubricant Solutions`,
      image: 'assets/landingPage/FuelAndLubes.webp',
      text: $localize`We offer a broad range of components for fuel and lubricant solutions, inlcuding polyisobutenes, synthetic base stocks, lubricant additives and components for metalworking fluids.`,
      link: 'https://automotive-transportation.basf.com/global/en/fuel-and-lubricants.html',
    },
    {
      header: $localize`Oilfield Chemicals`,
      image: 'assets/landingPage/Oilfield.webp',
      text: $localize`BASF offers a wide range of high quality chemicals for oilfield applications, including drilling, cementing, stimulation, and production additives. Our products enable customers to maximize value in their oilfield operations, and increase the overall efficiency of the well.`,
      link: 'https://energy-resources.basf.com/global/en/oilfield-chemicals.html',
    },
  ];

  readonly viewWebsiteLabel = $localize`View Website`;
}
